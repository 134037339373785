import { createStore } from 'vuex';
import menu from './menu.js';
import youdo from './moduleYoudo.js'
import auth from './moduleAuth.js'

export default createStore({
    modules: {
        auth,
        youdo,
    },
    state: {
        menu: menu,
    },
    mutations: {
        set(state, payload) {
            state[payload[0]] = payload[1];
        },
        clean(state, items) {
            Object.keys(items).forEach(key => {
                state[key] = items[key];
            });
        },
    },
    actions: {},
    getters: {},
});
