<template>
    <div class="auth">

        <div
            :style="{'background-image': 'url(' + background.src + ')'}"
            class="auth__cover"
            :class="{ clickable: background.href }"
            @click="goToLinkIfNeed()"
        ></div>

        <div class="auth__content">

            <div class="auth__logo">
                <SvgUse :width="40" :height="46" id="logoWebvorkOnWhite" key="Webvork"></SvgUse>
            </div>

            <router-view/>

        </div>

    </div>
</template>

<script>
export default {
    name: 'BaseAuth',
    props: [],
    data() {
        return {
            background: false,
            backgrounds: [
                {
                    src: '/images/auth-cover.jpg',
                    href: false,
                },
            ],
        };
    },
    computed: {},
    methods: {
        setRandomBg() {
            let randomIndex = Math.floor(Math.random() * this.backgrounds.length);
            this.background = this.backgrounds[randomIndex];
        },
        goToLinkIfNeed() {
            if (this.background.href) {
                window.open(this.background.href, '_blank').focus();
            }
        },
    },
    beforeMount() {
        this.setRandomBg();
    }
};
</script>

<style lang="scss">
    @import '../../scss/utils/mixins.scss';
    @import '../../scss/utils/variables.scss';

    .auth{
        width: 100%;
        height: 100%;
        &__cover{
            display: none;
            position: absolute;
            top:0;
            left:0;
            bottom:0;
            right: 380px;
            background-position: center center !important;
            background-size: cover !important;
            @include break(xl) {
                display: block;
            }
            &.clickable{
                cursor: pointer;
            }
        }
        &__content{
            position: absolute;
            top: 0;
            bottom: 0;
            right:0;
            background: white;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            max-height: 100vh;
            overflow-y: auto;
            @include break(xl) {
                width: 400px;
                border-radius: 20px 0 0 20px;
            }
            @media (max-height: 800px) {
                justify-content: flex-start;
            }
            &>*:first-child{
                margin-top: 50px;
            }
            &>*:last-child{
                margin-bottom: 40px;
            }
        }
        &__logo{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 72px;
            height: 72px;
            flex: none;
            border-radius: 50%;
            background: white;
            box-shadow: 0 7px 15px rgb(0 0 0 / 10%);
            margin: 0 0 50px;
            img {
                width: 40px;
                height: 40px;
                flex: none;
            }
        }
    }
</style>
