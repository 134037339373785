<template>
    <div class="layout__container">

<!--        <ScrollUp></ScrollUp>-->

        <template v-if="!$route.meta.isInternal">
            <BaseAuth></BaseAuth>
        </template>

        <template v-if="$route.meta.isInternal">

            <Sidebar></Sidebar>

            <div class="layout__wrapper">

                <Header></Header>

                <router-view></router-view>

                <Footer></Footer>

            </div>

        </template>

    </div>
</template>

<script>
import Header from '@/views/Layout/Header.vue';
import Footer from '@/views/Layout/Footer.vue';
import Sidebar from '@/views/Layout/Sidebar.vue';
import ScrollUp from '@/components/ScrollUp.vue';
import BaseAuth from '@/views/Auth/BaseAuth.vue';

export default {
    name: 'App',
    mounted() {
    },
    components: {
        Header,
        Footer,
        Sidebar,
        ScrollUp,
        BaseAuth,
    }
};
</script>

<style lang="scss">

@import './scss/utils/mixins.scss';
@import './scss/utils/variables.scss';

.layout {
    &__container {
        width: 100%;
        min-height: 100vh;
        height: 100%;
        display: flex;
        flex-direction: row;
        background-color: $color-day-bg;
    }

    // Wrapper
    &__wrapper {
        width: 100%;
        height: 100%;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: $color-day-bg;
        padding-left: 100px;
        padding-right: 30px;
        padding-bottom: 30px;

        font-family: 'Montserrat';
        font-style: normal;
        color: $color-day-dark-gray;

        @include minWidth(2060) {
            padding-left: 30px;
            margin-left: 275px;
        }

        @include maxWidth(950) {
            padding-left: 90px;
            padding-right: 20px;
            padding-bottom: 35px;
        }

        @include maxWidth(720) {
            padding-left: 20px;
            margin-left: 0;
        }

        @include maxWidth(475) {
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    // Content
    &__content {
        width: 100%;
        height: 100%;
        min-height: 100%;
        flex: 1;
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: stretch;
        align-content: flex-start;
        color: $color-day-black;
        position: relative;
        // overflow: hidden;

        @include maxWidth(720) {
            padding-top: 90px;
        }
    }
}

</style>
