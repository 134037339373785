<template>
    <div
        :style="computedStyles"
        class="AvatarLetter">
        <div class="AvatarLetter__sign">
            {{ letters }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'AvatarLetter',
    props: {
        size: {
            type: Number,
            required: false,
            default: 50
        },
        words: {
            type: Array,
            required: true,
            default: ['not available']
        }
    },
    data() {
        return {}
    },
    computed: {
        letters() {
            return (this.sentence+'')
                .trim()
                .split(' ')
                .map(word => { return word[0]})
                .join('')
        },
        sentence() {
            return WV.collect(this.words)
                .filter(sentence => { return this.ifSentenceValid(sentence) })
                .first()
        },
        computedStyles() {
            return {
                width: this.size+'px',
                height: this.size+'px',
                fontSize: (this.size/2.8)+'px',
            }
        },
    },
    methods: {
        ifSentenceValid(sentence) {
            return sentence !== null
                && sentence !== false
                && (''+sentence).trim().length > 0
        },

    },
    beforeMount() {
    }
}
</script>

<style lang="scss">
    .AvatarLetter{
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: linear-gradient(180deg, #33CCF5 0%, #0096EB 100%);
        position: relative;
        overflow: hidden;
        &__sign{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            text-transform: uppercase;
            color: white;
        }
    }
</style>
