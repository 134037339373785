<template>
    <div
        id="wv-scroll-up"
        class="wv-scroll-up">
    </div>
</template>

<script>

    /**
     * @component
     * @example <ScrollUp></ScrollUp>
     * // simple paste it
     */

    module.exports = {
        name: 'ScrollUp',
        props: [],
        data() {
            return {
                name: 'wv-scroll-up',
                isShow: false,
                scrollUp: false,
            };
        },
        computed: {
        },
        methods: {
            addEventScrool() {
                document.addEventListener('scroll', () => {
                    this.showScrollTo()
                })
            },
            showScrollTo() {
                if (pageYOffset >= 500) {
                    if (!this.isShow) {
                        this.scrollUp.addEventListener('click', this.scrollTo)
                        this.scrollUp.classList.add(`${this.name}--active`)
                        this.isShow = true
                    }
                } else if (this.isShow) {
                    this.scrollUp.removeEventListener('click', this.scrollTo)
                    this.scrollUp.classList.remove(`${this.name}--active`)
                    this.isShow = false
                }
            },
            scrollTo() {
                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                })
            },
        },
        created() {

        },
        mounted() {
            this.scrollUp = document.getElementById(this.name);
            if (!this.scrollUp) return;
            this.showScrollTo();
            this.addEventScrool();
        }
    };
</script>

<style lang="scss">

    @import '../scss/utils/mixins.scss';
    @import '../scss/utils/variables.scss';

    .wv-scroll-up {
        width: 50px;
        height: 50px;
        display: none;
        position: fixed;
        right: 30px;
        bottom: 50px;
        border-radius: 50%;
        background-color: $color-day-white;
        box-shadow: 0 15px 25px rgba($color-day-black, 0.1);
        z-index: $z-index-popup - 3;
        opacity: 0;
        cursor: pointer;

        &--active {
            display: block;
            animation: show-wv-settings-popup 0.2s linear 0s both;
        }
        @include maxWidth(950) {
            right: 20px;
        }
        @include maxWidth(475) {
            right: 10px;
        }
    }
</style>
