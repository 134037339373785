<template>
    <header id="webmaster-header-header" class="webmaster-header__header">

        <div class="wv-block--header">
            <h2>{{ $route.meta.title }}</h2>
        </div>

        <div class="webmaster-header__avatar">
<!--            <AvatarLetter
                :size="50"
                words="AB"
            ></AvatarLetter>-->
        </div>
    </header>
</template>

<script>
    export default {
        name: 'Header',
        props: [],
        data() {
            return {};
        },
        computed: {
            statMini() {
                return this.$store.state.webmaster.statMini || false
            },
            user() {
                return this.$store.state.auth.user
            },
            preparedAvatarWords() {
                let words = [];
                let firstName = typeof this.user.first_name !== 'object' && this.user.first_name !== ''
                    ? this.user.first_name.replaceAll(' ', '')
                    : ''
                let lastName = typeof this.user.last_name !== 'object' && this.user.last_name !== ''
                    ? this.user.last_name.replaceAll(' ', '')
                    : ''
                firstName+lastName !== ''
                    ? words.push(firstName+' '+lastName)
                    : words.push(this.user.username.replaceAll(' ', ''))
                return words
            }
        },
        methods: {
            openSidebar() {
                WV.Bus.emit('openSidebar')
            },
        },
        beforeMount() {
        },
        components: {

        }
    };
</script>

<style lang="scss">

    @import '../../scss/utils/mixins.scss';
    @import '../../scss/utils/variables.scss';

    .webmaster-header {
        &__burger{
            width: 24px;
            height: 24px;
            margin-right: 15px;
            cursor: pointer;
            @include break(md){
                display: none;
            }
        }
        &__header {
            width: 100%;
            height: 100px;
            max-height: 100px;
            display: flex;
            align-items: center;
            position: relative;

            @include maxWidth(720) {
                height: 70px;
                position: fixed;
                padding: 0 20px;
                background-color: $color-day-white;
                transform: translateY(0);
                transition: transform 0.2s;
                z-index: $z-index-menu - 1;
            }

            .wv-block--header {
                margin-right: auto;
            }
        }
        &__balance{
            justify-content: space-between;
            align-items: center;
            margin-right: 15px;
            display: none;
            position: relative;
            z-index: 1;
            @include break(md) {
                display: flex;
            }
            @include break(lg) {
                margin-right: 35px;
            }
            &_content{
                display: flex;
                flex-direction: column;
            }
            &_label{
                font-size: 12px;
                line-height: 16px;
                letter-spacing: 0.5px;
                text-align: right;
                font-family: $font-secondary;
                color: $color-day-dark-blue;
                opacity: .5;
            }
            &_sum{
                font-weight: bold;
                font-size: 18px;
                line-height: 22px;
                text-align: right;
                letter-spacing: 0.005em;
            }
            &_logo{
                width: 40px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 10px;
            }
            &__tooltip{
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                z-index: 3;
            }
        }

        &__profile {
            &-block {
                display: flex;
                flex-direction: row;
                font-family: $font-secondary;
                font-style: normal;
                letter-spacing: 1px;
                margin-right: 5px;

                @include maxWidth(720) {
                    display: none;
                }
            }

            &_info {
                display: flex;
                flex-direction: column;
                align-items: flex-end;

                @include maxWidth(1100) {
                    display: none;
                }
            }

            &_name {
                font-weight: bold;
                font-size: 17px;
                line-height: 23px;
                color: $color-day-lmenu-normal;
            }
            &_id {
                font-weight: normal;
                font-size: 14px;
                line-height: 16px;
                color: $color-day-form-shadow;
            }
            &_arrow {
                height: 50px;
                min-height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0 10px;
                cursor: pointer;
                img {
                    transition: transform 0.2s;
                }
                &.wv-popup--active {
                    img {
                        transform: rotateZ(180deg);
                    }
                }
            }
        }

        &__avatar {
            width: 50px;
            height: 50px;
            flex: none;
            position: relative;
            z-index: $z-index-avatar;

            @include maxWidth(720) {
                display: none;
            }

            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }

            &_notification {
                width: $size-icon-20;
                height: $size-icon-20;
                border: 1px solid $color-day-white;
                position: absolute;
                bottom: 0;
                right: 0;
                z-index: $z-index-avatar + 1;

                font-weight: bold;
                font-size: 12px;
                line-height: 14px;
                letter-spacing: 0.5px;
                color: $color-day-white;
            }
        }
    }

</style>
