export default {
    namespaced: true,
    state: () => ({
        employees: [],
        tasks: [],
        payroll: null,
    }),
    mutations: {
        set(state, payload) {
            state[payload[0]] = payload[1];
        },
        clean(state, items) {
            Object.keys(items)
                .forEach(key => {
                    state[key] = items[key];
                });
        },
    },
    actions: {
        getTasks(store) {
            return new Promise((resolve, reject) => {
                WV.Api.getYoudoTasks()
                    .then(response => {
                        store.commit('set', ['tasks', response]);
                        resolve(response);
                    });
            });
        },
        getEmployees(store) {
            return new Promise((resolve, reject) => {
                WV.Api.getYoudoEmployees()
                    .then(response => {
                        store.commit('set', ['employees', response]);
                        resolve(response);
                    });
            });
        },
        getPayroll(store) {
            return new Promise((resolve, reject) => {
                WV.Api.getYoudoPayroll()
                    .then(response => {
                        store.commit('set', ['payroll', response]);
                        resolve(response);
                    });
            });
        },
        createTask(store, task) {
            return new Promise((resolve, reject) => {
                WV.Api.createYoudoTask(task)
                    .then(response => {
                        resolve(response);
                        store.dispatch('getTasks');
                    });
            });
        },
        lockPayroll(store) {
            return new Promise((resolve, reject) => {
                WV.Api.lockYoudoPayroll().then(response => {
                    resolve(response);
                });
            });
        },
        finalizePayroll(store) {
            return new Promise((resolve, reject) => {
                WV.Api.finalizeYoudoPayroll().then(response => {
                    resolve(response);
                });
            });
        },
        syncEmployeesWithYoudo(store) {
            return new Promise((resolve, reject) => {
                WV.Api.syncEmployeesWithYoudo().then(response => {
                    resolve(response);
                });
            });
        },
        syncTasksWithYoudo(store) {
            return new Promise((resolve, reject) => {
                WV.Api.syncTasksWithYoudo().then(response => {
                    resolve(response);
                });
            });
        },
    },
    getters: {
        isPayrollLocked(state) {
            return state.payroll !== null
                ? state.payroll.is_locked === true
                : false
        },
        getAmountDebtByEmployee: (state) => (employee_id) => {
            return collect(state.tasks)
                .where('employee_id', employee_id)
                .map(task => {
                    task.amount_debt = task.amount_debt === null ? 0 : task.amount_debt;
                    return task;
                })
                .sum('amount_debt');
            //return .where('youdoId', youdoId).all() ;
        }
    }
};
