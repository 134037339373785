<template>
    <li>
        <a
            :href="path"
            @click.prevent="goToRoute"
            class="menu__item"
            :class="{'menu__item--active': currentPage}">
            <div class="menu__item_logo">
                <svg xmlns="http://www.w3.org/2000/svg"
                    width="26px"
                    height="26px"
                    class="menu__item_logo_svg">
                    <use
                        class="menu__item_logo_use"
                        :xlink:href="getElementXlinkHref">
                    </use>
                </svg>
            </div>

            <div class="menu__item_text">
                {{ element.name }}
            </div>

        </a>
    </li>
</template>

<script>
    export default {
        name: 'SidebarItem',
        props: {
            element: {
                type: Object,
                default: {
                    id: '',
                    name: '',
                    path: ''
                }
            },
        },
        computed: {
            getElementXlinkHref() {
                return '/images/icons.svg#menuIcon_' + this.element.id;
            },
            path() {
                return this.element.path;
            },
            currentPage() {
                return this.$route.path.includes(this.path);
            }
        },
        methods: {
            goToRoute() {
                if (this.path.indexOf('http') >= 0) {
                    window.open(this.path, '_blank')
                        .focus();
                } else {
                    this.$router.push(this.element.path);
                }
            },
        },
        components: {
        }
    };
</script>

<style lang="scss">

    @import '../../scss/utils/mixins.scss';
    @import '../../scss/utils/variables.scss';

    .layout__menu {
        .menu__item {
            height: 70px;
            display: flex;
            flex-direction: row;
            align-items: center;
            outline: none;
            cursor: pointer;

            &_logo {
                min-width: 70px;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: background-color 0.2s;
                &_svg{

                }
                use{
                    transition: fill .2s, stroke .2s;
                    fill: $color-day-lmenu-text;
                    stroke: $color-day-lmenu-text;
                }
            }

            &_text {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                color: $color-day-lmenu-text;
                padding: 0 20px;
                opacity: 0;
                transition: background-color 0.2s, color 0.2s, opacity 0.3s;

                font-family: $font-secondary;
                font-weight: normal;
                font-size: 18px;
                line-height: 21px;
                letter-spacing: 0.007em;
            }
            .fill--none {
                fill: none;
                stroke: $color-day-lmenu-text;
                transition: stroke 0.2s;
            }
            &:hover {
                .menu__item_logo,
                .menu__item_text {
                    background-color: $color-day-lmenu-active;
                }
            }
            &:hover, &:focus, &--active {
                use {
                    fill: $color-day-white;
                    stroke: $color-day-white;
                }

                .fill--none {
                    fill: none;
                    stroke: $color-day-white;
                }

                .menu__item_text {
                    color: $color-day-white;
                }
            }
        }
    }

</style>
