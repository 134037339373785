import '@/scss/main.scss';

/* Axios */
import axios from 'axios';
window.axios = axios;

/* EventBus */
import mitt from 'mitt';

/* collect.js */
import collect from 'collect.js';
window.collect = collect;

/* date-fns */
import dateFormat from 'date-fns/format';
import differenceInYears from 'date-fns/differenceInYears';

Date.prototype.addDays = function(days) {
    let date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

Date.prototype.getFormattedDate = function () {
    let d = new Date(this.valueOf());
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
};

/* API */
import Api from './Api';

/* Toast */
import Toastify from 'toastify-js';

/* Vue */
import { createApp } from 'vue';
import router from './router';
import store from './store';

/* Sentry */
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";


/* Register Components */
import App from './App.vue';
import DateRangePicker from './components/DateRangePicker/index';
import Loading from './components/Loading.vue';
import Alert from './components/Alert.vue';
import SvgUse from './components/SvgUse.vue';
import SortingIcons from './components/SortingIcons.vue';
import ScrollUp from './components/ScrollUp.vue';
import FormInput from './components/FormInput.vue';
import FormInputCurrency from './components/FormInputCurrency.vue';
import FormTextarea from './components/FormTextarea.vue';
import FormRadio from './components/FormRadio.vue';
import FormSelect from './components/FormSelect.vue';
import FormSelectMulti from './components/FormSelectMulti.vue';
import FormSwitch from './components/FormSwitch.vue';
import Card from './components/Card.vue';
import Modal from './components/Modal.vue';
import AvatarLetter from './components/AvatarLetter.vue';
import InView from './components/InView.vue';
import ShowMore from './components/ShowMore.vue';
import UploadCsv from './components/UploadCsv.vue';

window.WV = {
    validations: require('./libs/validations.js').default,
    collect: require('collect.js'),
    Bus: mitt(),
    Date: {
        format: dateFormat,
        differenceInYears: differenceInYears,
    },
    Money: {
        RUB: (amount) => Intl.NumberFormat('Ru-ru', { minimumFractionDigits: 2 }).format(amount),
    },
    store,
    router,
    toast: {
        success: (message, duration = 3000) => {
            Toastify({
                text: message,
                duration: duration,
                close: false,
                gravity: 'top',
                position: 'right',
                backgroundColor: 'linear-gradient(to right, #0e9472, #44dab3)',
                stopOnFocus: true,
                onClick: () => {
                }
            })
                .showToast();
        },
        error: (message, duration = 3000) => {
            Toastify({
                text: message,
                duration: duration,
                close: true,
                gravity: 'top',
                position: 'right',
                backgroundColor: 'linear-gradient(to right, #ff0100, #c90706)',
                stopOnFocus: true,
                onClick: () => {
                }
            })
                .showToast();
        }
    },
    Api: new Api(),
};

WV.app = createApp(App)
    .use(store)
    .use(router)
    .component('AvatarLetter', AvatarLetter)
    .component('Loading', Loading)
    .component('Alert', Alert)
    .component('SvgUse', SvgUse)
    .component('FormInput', FormInput)
    .component('FormInputCurrency', FormInputCurrency)
    .component('FormTextarea', FormTextarea)
    .component('FormRadio', FormRadio)
    .component('FormSelect', FormSelect)
    .component('FormSelectMulti', FormSelectMulti)
    .component('FormSwitch', FormSwitch)
    .component('Card', Card)
    .component('Modal', Modal)
    .component('SortingIcons', SortingIcons)
    .component('DateRangePicker', DateRangePicker)
    .component('InView', InView)
    .component('ShowMore', ShowMore)
    .component('UploadCsv', UploadCsv);

Sentry.init({
    app: WV.app,
    dsn: "https://34ffac246496441bb67f57b4e65fe6fc@sentry.webvork.net/38",
    integrations: [
        new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            //tracingOrigins: ["localhost", "my-site-url.com", /^\//],
        }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

WV.app.directive('click-outside', {
    beforeMount: (el, binding) => {
        el.clickOutsideEvent = event => {
            if (!(el === event.target || el.contains(event.target))) {
                binding.value();
            }
        }
        document.addEventListener("click", el.clickOutsideEvent)
    },
    unmounted: el => { document.removeEventListener("click", el.clickOutsideEvent) },
})

/* Shorts */
WV.app.config.globalProperties.$WV = WV;
WV.app.config.globalProperties.$validations = WV.validations;

/* Mount app */
WV.app.mount('#app');
