import { createRouter, createWebHistory } from 'vue-router';

const routes = [
    {
        path: '/',
        name: 'login',
        component: () => import(/* webpackChunkName: "auth" */'../views/Auth/Login.vue'),
        meta: {
            isInternal: false,
            title: 'Login'
        }
    },
    {
        path: '/youdo',
        name: 'youdo',
        component: () => import(/* webpackChunkName: "youdo" */'../views/Youdo/Youdo.vue'),
        meta: {
            isInternal: true,
            title: 'YouDo management'
        }
    },
    {
        path: '/youdo/approve-payroll/:hash',
        name: 'youdoPayrollApprove',
        component: () => import(/* webpackChunkName: "youdo" */'../views/Youdo/YoudoPayrollApprove.vue'),
        meta: {
            isInternal: false,
            title: 'YouDo'
        }
    },


];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
