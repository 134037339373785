export class BaseApi {

    /**
     * @constructor
     */
    constructor() {
        this.currentDomainZone = document.location.hostname.split('.')[document.location.hostname.split('.').length - 1];

        this.baseUrl = this.currentDomainZone === 'test'
            ? 'http://localhost:3000/'
            : 'https://youdo-api.webvork.com/';

            this.config = {};
    }

    /**
     * Prepare request headers
     */
    prepareRequest() {
        this.config.headers = {
            Authorization: `Bearer ${WV.store.getters['auth/getToken']}`
        };
    }

    /**
     * GET request
     * @method get
     * @param {string} endpoint - URL
     */
    get(endpoint) {
        this.prepareRequest();
        return new Promise((resolve, reject) => {
            axios.get(this.baseUrl + endpoint, this.config)
                .then(response => {
                    resolve(response.data);
                })
                .catch(response => {
                    reject(response);
                    this.requestRejected(response);
                });
        });
    }

    /**
     * POST request
     * @method post
     * @param {string} endpoint - URL
     * @param {object} payload - Request payload
     */
    post(endpoint, payload) {
        this.prepareRequest();
        return new Promise((resolve, reject) => {
            axios.post(this.baseUrl + endpoint, payload, this.config)
                .then(response => {
                    resolve(response.data);
                })
                .catch(response => {
                    reject(response);
                    this.requestRejected(response);
                });
        });
    }

    /**
     * PUT request
     * @method put
     * @param {string} endpoint - URL
     * @param {object} payload - Request payload
     */
    put(endpoint, payload) {
        this.prepareRequest();
        return new Promise((resolve, reject) => {
            axios.put(this.baseUrl + endpoint, payload, this.config)
                .then(response => {
                    resolve(response.data);
                })
                .catch(response => {
                    reject(response);
                    this.requestRejected(response);
                });
        });
    }

    /**
     * Delete request
     * @method delete
     * @param {string} endpoint - URL
     * @param {object} payload - Request payload
     */
    delete(endpoint, payload) {
        this.prepareRequest();
        this.config.data = payload;
        return new Promise((resolve, reject) => {
            axios.delete(this.baseUrl + endpoint, this.config)
                .then(response => {
                    resolve(response.data);
                })
                .catch(response => {
                    reject(response);
                    this.requestRejected(response);
                });
        });
    }

    requestRejected(error) {
        if (typeof error.response.status !== 'undefined') {

            if (error.response.status === 401) {
                const isLoginRequest = error.request.responseURL.indexOf('auth/login') > 0;
                if (!isLoginRequest) {
                    WV.store.dispatch('auth/logout');
                    WV.router.push("/");
                }
            }
            if (error.response.status === 500) {
                WV.toast.error(error.response.data.message, 10000);
            }
        }
    }

}

export default class Api extends BaseApi {
    constructor() {
        super();
    }

    getYoudoTasks() {
        return this.get('youdo/tasks');
    }

    postLogin(username, password) {
        return this.post('auth/login', {username: username, password: password});
    }

    createYoudoTask(task) {
        return this.post('youdo/tasks', task);
    }

    syncTasksWithYoudo() {
        return this.get('youdo/tasks/sync');
    }

    getYoudoEmployees() {
        return this.get('youdo/employees');
    }

    syncEmployeesWithYoudo() {
        return this.get('youdo/employees/sync');
    }

    getYoudoPayroll() {
        return this.get('youdo/payroll');
    }

    getYoudoPayrollByHash(hash) {
        return this.post('youdo/payroll-by-hash', {hash: hash});
    }

    approveYoudoPayroll(hash) {
        return this.post('youdo/payroll/approve', {hash: hash});
    }

    rejectYoudoPayroll(hash) {
        return this.post('youdo/payroll/reject', {hash: hash});
    }

    lockYoudoPayroll() {
        return this.get('youdo/payroll/lock');
    }

    finalizeYoudoPayroll() {
        return this.get('youdo/payroll/finalize');
    }

}
