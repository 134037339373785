<template>
    <nav
        id="layout__menu"
        class="layout__menu"
        :class="{'layout__menu-expanded': isSidebarExpanded}"
    >

        <div
            v-if="isSidebarExpanded"
            @click="closeSidebar"
            class="sidebarCloseOverlay"
        ></div>

        <ul class="menu__container">

            <!-- Menu logo -->
            <li class="menu__header">
                <div class="menu__header_logo">
                    <img
                        src="/images/menu-webvork-logo.svg"
                        alt="logo"/>
                </div>
                <div class="menu__header_text">
                    <img
                        src="/images/menu-text.svg"
                        alt="SE Management"/>
                </div>
            </li>

            <SidebarItem
                v-for="element in elements"
                :element="element"
            ></SidebarItem>

        </ul>
    </nav>
</template>

<script>
import SidebarItem from '@/views/Layout/SidebarItem.vue';

export default {
    name: 'Sidebar',
    props: {},
    data() {
        return {
            isSidebarExpanded: false,
        };
    },
    computed: {
        elements() {
            return this.$store.state.menu;
        },
    },
    methods: {
        preventBodyScrollIfSidebarActive() {
            const menuContainer = document.getElementsByClassName('menu__container')[0];
            menuContainer.addEventListener('mouseenter', () => {
                //
            });
            menuContainer.addEventListener('mouseleave', () => {
                document.body.classList.remove('ovh');
            });
        },
        openSidebar() {
            this.isSidebarExpanded = true;
            document.body.classList.add('ovh');
        },
        closeSidebar() {
            this.isSidebarExpanded = false;
            document.body.classList.remove('ovh');
        },
    },
    components: {
        SidebarItem,
    },
    mounted() {
        WV.Bus.on('openSidebar', this.openSidebar);
        WV.Bus.on('closeSidebar', this.closeSidebar);
        //this.preventBodyScrollIfSidebarActive();
    }
};
</script>

<style lang="scss">

@import '../../scss/utils/mixins.scss';
@import '../../scss/utils/variables.scss';

// Миксин раскрывания основного меню
@mixin showMenu() {
    width: 300px;
    background-color: $color-day-lmenu-normal;
    .menu__item_text,
    .menu__header_text {
        opacity: 1;
    }
}

.sidebarCloseOverlay {
    position: absolute;
    background: transparent;
    top: 0;
    bottom: 0;
    left: 0;
    right: -1000px;
    z-index: $z-index-menu - 300;
}

.layout__menu {
    min-width: 70px;
    width: 70px;
    min-height: 100%;
    height: 100%;
    position: fixed;
    background-color: $color-day-lmenu-normal;
    z-index: $z-index-menu;
    @include maxWidth(720) {
        transition: transform 0.3s;
        transform: translateX(-300px);
    }
    &-expanded {
        transition: transform 0.3s;
        transform: translateX(0);
    }
    .menu {
        &__container {
            width: 70px;
            height: 100%;
            background-color: transparent;
            display: flex;
            overflow-y: auto;
            flex-direction: column;
            position: absolute;
            top: 0;
            left: 0;
            font-family: $font-secondary;
            z-index: $z-index-menu - 100;
            transition: background-color 0.2s;
            &::-webkit-scrollbar {
                width: 0;
            }
            @include minWidth(2060) {
                @include showMenu();
            }

            @include maxWidth(720) {
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
                transition: background-color 0.2s;
                @include showMenu();
            }

            &:hover {
                @include showMenu();
            }
        }

        &__header,
        &__settings {
            display: flex;
            flex-direction: row;
            align-items: center;
            outline: none;
            cursor: pointer;

            &_logo {
                min-width: 70px;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: background-color 0.2s;
            }

            &_text {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                color: $color-day-lmenu-text;
                padding-left: 20px;
                opacity: 0;
                transition: background-color 0.2s, color 0.2s, opacity 0.3s;

                font-family: $font-secondary;
                font-weight: normal;
                font-size: 18px;
                line-height: 21px;
                letter-spacing: 0.007em;
            }
        }

        &__header {
            min-height: 100px;
            cursor: default;

            &_logo,
            &_text {
                border-bottom: 1px solid $color-day-black;
            }

            &_text{
                img{
                    height: 16px;
                }
            }


            &_isopen {
                @include squareBlock(20px);
                align-self: center;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 30px;
                margin-top: 3px;
                margin-bottom: 0;
                cursor: pointer;

                svg {
                    width: 100%;
                    height: 100%;
                    path {
                        fill: $color-day-lmenu-text;
                        transition: fill .2s;
                    }
                }

                @include maxWidth(1050) {
                    display: none;
                }

                @include minWidth(2060) {
                    display: none;
                }
            }
        }

    }

    // Закрепление меню
    &--isopen {
        display: none;

        &:checked {
            ~ .layout__menu .menu__container {
                @include minWidth(1050) {
                    @include showMenu();
                }
            }

            ~ .layout__menu .menu__header_isopen svg path {
                fill: $color-day-white;
            }

            ~ .layout__wrapper {
                width: calc(100% - 285px);
                padding-left: 30px;
                margin-left: 275px;

                @include maxWidth(1050) {
                    width: 100%;
                    margin-left: 0;
                    padding-left: 90px;
                }

                @include maxWidth(720) {
                    padding-left: 20px;
                }

                @include maxWidth(475) {
                    padding-left: 10px;
                }
            }

        }
    }

    // Активный элемент меню
    &--active {
        transform: translateX(0);

        .menu__container {
            @include showMenu();
        }
    }
    .menu__container {
      min-height: 100vh;
      height: calc(100vh + 14px);
      margin-bottom: 10px;
      @include minWidth(2060) {
           height: 100vh;
           margin-bottom: 0;
        }
      &:hover {
        height: 100%;
      }
    }
}
</style>
