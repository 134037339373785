<template>
    <div
        class="alert"
        :class="[addclass, 'alert-'+type]">

        <div class="alert__icon">
            <SvgUse :width="20" :height="20" id="alert" key="icon-alert"></SvgUse>
        </div>

        <div class="alert__message">
            <template v-if="message.length">
                {{ message }}
            </template>
            <slot></slot>
        </div>

    </div>
</template>

<script>
    export default {
        name: 'Alert',
        props: {
            addclass: {
                type: String,
                default: ''
            },
            type: {
                type: String,
                default: 'danger'
            },
            message: {
                type: String,
                default: ''
            }
        },
        data() {
            return {};
        },
        computed: {},
        methods: {},
        beforeMount() {
        }
    }
</script>

<style lang="scss">

    @import '../scss/utils/mixins.scss';
    @import '../scss/utils/variables.scss';

    .alert{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        padding: 20px;
        line-height: 18px;
        font-size: 14px;
        font-family: $font-secondary;
        border-radius: 5px;
        &__icon{
            height: 20px;
            width: 20px;
            margin-right: 20px;
        }
        &__message{}
        &-danger{
            background: rgba($color-day-error, 10%);
            color: $color-day-error;
        }
    }
</style>